import React from "react"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import Card from "./card"

const Especialidades = ({data}) => {
  return(
    <StyledContainer>
      <h4 className={font.nexaBold}>{data.subtitle}</h4>
      <h1 className={font.financierMediumItalic}>{data.title}</h1>
      <div className="list">
        {data.specialties.map((data, i)=>(
          <Card data={data} key={i} />
        ))}
      </div>
    </StyledContainer>
  )
}


export default Especialidades

const StyledContainer = styled.div`
  margin: 200px 0;
  h4, h1{text-align: center;}
  h1{color: #e6e6e6; margin: 0.8em auto; width: 20%; line-height: 0.8em;}
  h4{color: #717171; text-transform: uppercase;}
  .list{
    width: calc(645px + 1515 * (100vw - 769px) / 1791);
    @media only screen and (min-width: 2560px){width: 2160px;}
    @media only screen and (max-width: 768px){width: 645px;}
    display: flex;
    justify-content: space-between;
    margin: 4rem auto;
  }
`