import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import GlassCard from "./glassCard"
import setaD from "../images/svg/setaD.svg"
import setaDW from "../images/svg/setaBigD.svg"
import saibaMais from "../images/svg/saibaMais.svg"
import bqdc from "../images/svg/BQDC_logo.svg"

const Intro = ({data}) => {

  const [saber, setSaber] = useState(false);

  return(
    <StyledCards>
      <div className="card">
        <GlassCard>
          <h5 className={font.nexaRegular + " title"}>{data.card1.title}</h5>
          <hr className="line"/>
          <p className={font.nexaXBold + " text"}>{data.card1.text}</p>
          <div className="cardImage">
            <Link to="/clinica#clinicaSlider" className="saibaBtn" type="submit" onMouseEnter={()=>{setSaber(true)}} onMouseLeave={()=>{setSaber(false)}}>
              <img src={saibaMais} alt="btn"/>
                <CSSTransition in={saber} timeout={350} classNames={"switch"} unmountOnExit>
                  <img src={setaD} alt="Picker"/>
                </CSSTransition>
                <CSSTransition in={!saber} timeout={350} classNames={"switch"} unmountOnExit>
                  <img src={setaDW} alt="Picker"/>
                </CSSTransition>
            </Link>
          </div>
        </GlassCard>
      </div>

      <div className="card">
        <GlassCard>
          <a href="https://bqdentalcenters.pt/" target="_blank" rel="noreferrer">
            <h5 className={font.nexaRegular + " title"}>{data.card2.title}</h5>
            <hr className="line"/>
            <p className={font.nexaXBold + " text"}>{data.card2.text}</p>
            <div className="cardImage">
              <img src={bqdc} alt="btn"/>
            </div>
          </a>
        </GlassCard>
      </div>
    </StyledCards>
  )
}

export default Intro

const StyledCards = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -40vh auto 10rem auto;
  width: 70%;
  color: #717171;
  position: relative;
  z-index: 2;
  .card{
    width: calc(240px + 300 * (100vw - 769px) / 1791);
    text-align: center;
    margin: 0 15px;
    @media only screen and (min-width: 2560px){
      width: 540px;
    }
    @media only screen and (max-width: 768px){
      width: 240px;
    }
  }
  .title{
    text-transform: uppercase;
  }
  .line{
    width: 40%;
    border: none;
    margin: 1em auto;
    border-top: #717171 1px solid;
  }
  .text{
    margin-top: 1.5em;
    height: 6em;
  }
  .cardImage{
    width: 50%;
    margin: auto;
    img{max-width: 100%;}
  }
  .saibaBtn{
    border: 1px solid transparent;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30% auto 0;
    img{
      height: 1em;
    }
  }

  .switch-enter{
    opacity: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`