import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Intro from "../components/intro"
import IntroCards from "../components/homeIntroCards"
import CorpoClinico from "../components/introCorpoClinico"
import Especialidades from "../components/introEspecialidades"
import Form from "../components/introForm"
import Testemunhos from "../components/testemunhos"
import Footer from "../components/footer"

import HeaderMobile from "../components/headerMobile"
import ConsultaMobile from "../components/consultaMobile"
import CorpoClinicoMobile from "../components/introCCMobile"
import EspecialidadesMobile from "../components/introEspecialidadesMobile"
import FormMobile from "../components/introFormMobile"
import TestemunhosMobile from "../components/testemunhosMobile"
import FooterMobile from "../components/footerMobile"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Home" />
    {useBreakpoint().mobile ? (
      <>
        <HeaderMobile header={data.globalJson.header} logo="big"/>
        <Intro data={data.homeJson.intro} mobile={true}/>
        <ConsultaMobile />
        <CorpoClinicoMobile data={data.homeJson.corpoClinico} team={data.globalJson.team}/>
        <EspecialidadesMobile data={data.homeJson.especialidades}/>
        <FormMobile data={data.homeJson.formacao}/>
        <TestemunhosMobile data={data.globalJson.testemunhos}/>
        <FooterMobile data={data.globalJson.footer}/>
      </>
      ) : (
      <>
        <Header header={data.globalJson.header}/>
        <Intro data={data.homeJson.intro}/>
        <IntroCards data={data.homeJson.introCards}/>
        <CorpoClinico data={data.homeJson.corpoClinico} team={data.globalJson.team}/>
        <Especialidades data={data.homeJson.especialidades}/>
        <Form data={data.homeJson.formacao}/>
        <Testemunhos data={data.globalJson.testemunhos}/>
        <Footer data={data.globalJson.footer}/>
      </>)
    }
  </Layout>
)

export default IndexPage

export const Json = graphql`
  query index {
    globalJson{
      header{
        menu{
          title
          link
        }
        menuselect{
          publicURL
          childImageSharp {
            fluid(quality: 70, maxWidth: 500) {
            ...GatsbyImageSharpFluid
            }
          }
        }
      }
      testemunhos{
        title
        subtitle
        quotes{
          text
          author
        }
      }
      footer{
        title
        calendarImg {
          publicURL
        }
        calendarTxt
        calendarLink
        schedule {
          days
          hours
        }
        phone {
          ind
          number
          txt
        }
        address
        links {
          title
          link
        }
        map {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        bottomTxt
        social
        {
          face
          insta
          tube
        }
      }
      team{
        title
        name
        position
        omd
        specialty
        photo{
          childImageSharp {
            fluid(quality: 70, maxWidth: 800) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        bio
      }
    }
    homeJson {
      intro{
        image{
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
      }
      introCards{
        card1{
          title
          text
          image{
            childImageSharp {
              fluid(quality: 70, maxWidth: 500) {
              ...GatsbyImageSharpFluid
              }
            }
          }
        }
        card2{
          title
          text
          image{
            childImageSharp {
              fluid(quality: 70, maxWidth: 500) {
              ...GatsbyImageSharpFluid
              }
            }
          }
        }
        form{
          title
          text
          name
          phone
          date
          email 
        }
      }
      corpoClinico{
        title
        subtitle
        text
        btnTxt
      }
      especialidades{
        subtitle
        title
        specialties{
          title
          img{
            publicURL
          }
          link
        }
      }
      formacao{
        img{
          childImageSharp {
            fluid(quality: 70, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        title
        subtitle
        text
        btnImg{
          childImageSharp {
            fluid(quality: 70, maxWidth: 200) {
            ...GatsbyImageSharpFluid
            }
          }
        }
        btnTxt
        form
        form1
        form2
        form3
        form4
      }
    }
  }
`