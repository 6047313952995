import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"
import Img from "gatsby-image"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import { Button } from "./buttons"

import seta from "../images/svg/setaD.svg"
import setaHover from "../images/svg/setaBigD.svg"

const CorpoClinico = ({data, team}) => {
  const [setaCorpo, setSetaCorpo] = useState(false);

  const [slide, setSlide] = useState(0);

  function changeSlide(){
    if(slide===team.length-1){
      setSlide(0);
    }
    else{
      setSlide(slide+1);
    }
  }

  return(
    <StyledCorpo>
      <div className="title">
        <h1 className={font.financierMediumItalic}>{data.title}</h1>
      </div>

      <div className="text">
        <p className={font.nexaRegular}>{data.text}</p>
        <Link to="/clinica#corpo-clinico" className="btn" onMouseEnter={()=>{setSetaCorpo(true)}} onMouseLeave={()=>{setSetaCorpo(false)}}>
          <CSSTransition in={setaCorpo} timeout={350} classNames={"switch"} unmountOnExit>
            <img src={seta} alt="seta"/>
          </CSSTransition>
          <CSSTransition in={!setaCorpo} timeout={350} classNames={"switch"} unmountOnExit>
            <img src={setaHover} alt="seta"/>
          </CSSTransition>
          <p className={font.nexaBold  + " small"}>{data.btnTxt}</p>
        </Link>
      </div>

      <div className="slider">
        {team.map((dataSlide, i)=>(
          <CSSTransition in={slide===i} timeout={350} classNames={"switch"} unmountOnExit key={"iCC" + i}>
            <div className="slide" key={"iCC2-" + i}>
              <div className="grey">
                {dataSlide.photo && 
                  <div className="profileImg">
                    <Img fluid={dataSlide.photo.childImageSharp.fluid} style={{height: `100%`}} imgStyle={{objectFit: "contain", objectPosition: "center bottom"}}/>
                  </div>
                } 
                <div className="top">
                  <h4 className={font.nexaXBold}>{dataSlide.title}</h4>
                  <h4 className={font.nexaXBold}>{dataSlide.name}</h4>
                  <p className={font.nexaLight}>{dataSlide.position}</p>
                  {dataSlide.omd!=="" ? 
                    <p className={font.nexaRegular}>{dataSlide.omd}</p>
                    : 
                    <p className={font.nexaRegular}>Fisioterapeuta</p>
                  }
                  <p className={font.nexaRegular}>{dataSlide.specialty}</p>
                </div>
                <Button className="btnNext" onClick={changeSlide}>
                  <img src={setaHover} alt="seta"/>
                </Button>
              </div>

              <div className="blue">
                <div className={font.nexaRegular + " bio"}>
                  {dataSlide.bio.map((data, i)=>(
                    <p key={"bio" + dataSlide.name + i}>{data}{i!==dataSlide.bio.length-1 && <><br/><br/></>}</p>
                  ))}
                </div>

                <div className="selector">
                  <div className={font.nexaRegular + " counter"}>
                    {i+1 + "/" + team.length}
                  </div>
                </div>
              </div>
            </div>
          </CSSTransition>
        ))}
      </div>
    </StyledCorpo>
  )
}


export default CorpoClinico

const StyledCorpo = styled.div`
  width: 100%;
  overflow: hidden;
  color: #717171;
  margin-top: 5vh;
  .title{
    padding: 0 10vw;
    h1{color:#E6E6E6; text-align: center;}
  }
  .text{
    margin: 5vh 10vw 5vh;
    p{max-width: 400px;}
  }
  .btn{
    position: relative;
    margin-top: 2em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #17D4E3;
    width: fit-content;
    img{
      height: 1em;
      margin-right: 0.5em;
    }
  }

  .slider{
    overflow: hidden;
    position: relative;
    width: 100%;
    .slide{
      width: 100%;
      height: 100%;
      .grey{
        position: relative;
        width: 100%;
        display: flex;
        letter-spacing: unset;
        p{text-transform: uppercase}
        .top{
          align-self: flex-end;
          padding-bottom: 3vh;
          position: relative;
          left: -10vw;
          p{margin-top: 1rem;}h4{line-height: 1.5rem;}
        }
        .profileImg{
          align-self: bottom;
          width: 55vw;
          min-width: 220px;
          position: relative;
          left: -5vw;
        }
        .btnNext{
          position: absolute;
          right: 4vw;
          bottom: 7em;
          width: 3em;
          img{
            width: 3em;
          }
        }
      }
      .blue{
        width: 100%;
        background-color: #2a94c3;
        padding: 2em 0;
        .bio{
          margin-left: 2em;
          color: #fff;
          width: 70%;
        }
        .selector{
          position: absolute;
          bottom: 8%;
          right: 5%;
          width: 5%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .counter{
          color: #fff;
          width: fit-content;
          margin-top: 10%;
        }
      }
    }
  }

  .switch-enter{
    opacity: 0 !important;
    position: absolute;
    left: 0;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`