import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import { Link } from "gatsby"
import Img from "gatsby-image"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import { Button } from "./buttons"

import setaE from "../images/svg/Seta_Esquerda1.svg"
import setaEF from "../images/svg/Seta_Esquerda2.svg"

import setaD from "../images/svg/Seta_Direita1.svg"
import setaDF from "../images/svg/Seta_Direita2.svg"

import seta from "../images/svg/setaD.svg"
import setaHover from "../images/svg/setaBigD.svg"

const CorpoClinico = ({data, team}) => {
  const [setaCorpo, setSetaCorpo] = useState(false);
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);

  const [slide, setSlide] = useState(0);

  function changeSlide(n){
    if(n===-1){
      if(slide===0){
        setSlide(team.length-1);
      }
      else{
        setSlide(slide+n);
      }
    }
    else{
      if(slide===team.length-1){
        setSlide(0);
      }
      else{
        setSlide(slide+n);
      }
    }
  }

  return(
    <StyledCorpo>
      <div className="title">
        <h1 className={font.financierMediumItalic}>{data.title}</h1>
      </div>

      <div className="text">
        <p className={font.nexaRegular}>{data.text}</p>
        <Link to="/clinica#corpo-clinico" className="btn" onMouseEnter={()=>{setSetaCorpo(true)}} onMouseLeave={()=>{setSetaCorpo(false)}}>
          <CSSTransition in={setaCorpo} timeout={350} classNames={"switch"} unmountOnExit>
            <img src={seta} alt="seta"/>
          </CSSTransition>
          <CSSTransition in={!setaCorpo} timeout={350} classNames={"switch"} unmountOnExit>
            <img src={setaHover} alt="seta"/>
          </CSSTransition>
          <p className={font.nexaBold  + " small"}>{data.btnTxt}</p>
        </Link>
      </div>

      <div className="slider">
        {team.map((dataSlide, i)=>(
          <CSSTransition in={slide===i} timeout={350} classNames={"switch"} unmountOnExit key={"iCC" + i}>
            <div className="slide" key={"iCC2-" + i}>
              <div className="grey">
                <div className="top">
                  <h4 className={font.nexaXBold}>{dataSlide.title}</h4>
                  <h4 className={font.nexaXBold}>{dataSlide.name}</h4>
                  <p className={font.nexaLight}>{dataSlide.position}</p>
                </div>
                <div className="bottom">
                  {dataSlide.omd!=="" ? 
                    <p className={font.nexaRegular}>{dataSlide.omd}</p>
                    : 
                    <p className={font.nexaRegular}>Fisioterapeuta</p>
                  }
                  <p className={font.nexaRegular}>{dataSlide.specialty}</p>
                </div>
              </div>

              <div className="blue">
                <div className={font.nexaRegular + " bio"}>
                  {dataSlide.bio.map((data, i)=>(
                    <p key={"bio" + dataSlide.name + i}>{data}{i!==dataSlide.bio.length-1 && <><br/><br/></>}</p>
                  ))}
                </div>

                <div className="selector">
                  <div className="btns">
                    <Button className="prev" onClick={()=>changeSlide(-1)} onMouseEnter={()=>{setPrev(true)}} onMouseLeave={()=>{setPrev(false)}}>
                      <CSSTransition in={prev} timeout={350} classNames={"switch"} unmountOnExit>
                        <img src={setaEF} alt="seta"/>
                      </CSSTransition>
                      <CSSTransition in={!prev} timeout={350} classNames={"switch"} unmountOnExit>
                        <img src={setaE} alt="seta"/>
                      </CSSTransition>
                    </Button>
                    <Button className="next" onClick={()=>changeSlide(1)} onMouseEnter={()=>{setNext(true)}} onMouseLeave={()=>{setNext(false)}}>
                      <CSSTransition in={next} timeout={350} classNames={"switch"} unmountOnExit>
                        <img src={setaDF} alt="seta"/>
                      </CSSTransition>
                      <CSSTransition in={!next} timeout={350} classNames={"switch"} unmountOnExit>
                        <img src={setaD} alt="seta"/>
                      </CSSTransition>
                    </Button>
                  </div>
                  <p className={font.nexaRegular + " counter"}>
                    {i+1 + "/" + team.length}
                  </p>
                </div>
              </div>

              {dataSlide.photo && 
                <div className="profileImg">
                  <Img fluid={dataSlide.photo.childImageSharp.fluid} style={{height: `100%`}} imgStyle={{objectFit: "contain", objectPosition: "center bottom"}}/>
                </div>
              }
            </div>
          </CSSTransition>
        ))}
      </div>
    </StyledCorpo>
  )
}


export default CorpoClinico

const StyledCorpo = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  color: #717171;
  .title{
    margin-left: 15vw;
    width: 400px;
    h1{color:#E6E6E6;}
  }
  .text{
    margin: 3em 15vw 0;
    p{
      max-width: calc(260px + 380 * (100vw - 769px) / 1791);
      @media only screen and (min-width: 2560px){
        max-width: 640px;
      }
      @media only screen and (max-width: 768px){
        max-width: 260px;
      }
    }
  }
  .btn{
    position: relative;
    margin-top: 2em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #17D4E3;
    width: fit-content;
    img{
      height: 1em;
      margin-right: 0.5em;
    }
  }

  .slider{
    overflow: hidden;
    display: flex;
    position: relative;
    transition: left 0.6s;
    left: 0;
    width: 100%;
    height: 30vw;
    @media only screen and (max-width: 1200px){
      height: calc(295px + 65 * (100vw - 769px) / 311);
    }
    @media only screen and (max-width: 768px){
      height: 295px;
    }
    .slide{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      position: absolute;
      left: 0;
      .grey, .blue{
        padding: calc(16px + 44 * (100vw - 769px) / 1791) 0;
        @media only screen and (min-width: 2560px){
          padding: 60px 0;
        }
        @media only screen and (max-width: 768px){
          padding: 16px 0;
        }
      }
      .grey{
        width: 50%;
        height: 70%;
        background-color: #E6E6E6;
        h4, p{
          margin-left: 15vw;
          @media only screen and (max-width: 1080px){
            margin-left: calc(45px + 115 * (100vw - 769px) / 311);
          }
          @media only screen and (max-width: 768px){
            margin-left: 45px;
          }
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        letter-spacing: unset;
        p{text-transform: uppercase}
        .top{p{margin-top: 1em;}h4{line-height: 1.2em;}}
      }
      .blue{
        width: 50%;
        min-height: 58%;
        background-color: #2a94c3;
        display: flex;
        align-items: center;
        .bio{
          margin-left: 15vw;
          color: #fff;
          width: 45%;
          @media only screen and (max-width: 1200px){
            width: calc(210px + 40 * (100vw - 769px) / 311);
            margin-left: calc(90px + 90 * (100vw - 769px) / 311);
          }
          @media only screen and (max-width: 768px){
            width: 210px;
            margin-left: 90px;
          }
        }
        .selector{
          position: absolute;
          bottom: 8%;
          right: 5%;
          width: 5%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .btns{
          width: 100%;
          display: flex;
          justify-content: space-between;
          .prev, .next{
            width: 40%;
            img{width: 100%;}
          }
        }
        .counter{
          color: #fff;
          width: fit-content;
          margin-top: 10%;
        }
      }
      .profileImg{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 28%;
      }
    }
  }

  .switch-enter{
    opacity: 0 !important;
    position: absolute;
    left: 0;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`