import React from "react"
import Img from "gatsby-image"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

const Form = ({data}) => {

  return(
    <StyledForm>
      <h4 className={font.nexaBold + " title"}>{data.title}</h4>
      <h1 className={font.financierMediumItalic}>{data.subtitle}</h1>
      <Img fluid={data.img.childImageSharp.fluid} style={{width: `100%`, height: `40vw`}} imgStyle={{objectFit: "cover"}}/>

      <div className="box">
        <div className="text">
          <p className={font.nexaRegular}>{data.text}</p>
        </div>
        <div className="forms">
          <div>
            <p className={font.nexaRegular + " form"}>{data.form}</p>
            <p className={font.nexaRegular + " form"}>{data.form1}</p>
            <hr className="line"/>  
          </div>
          <div>
            <p className={font.nexaRegular + " form"}>{data.form}</p>
            <p className={font.nexaRegular + " form"}>{data.form2}</p>
            <hr className="line"/>
          </div>
          <div>
            <p className={font.nexaRegular + " form"}>{data.form}</p>
            <p className={font.nexaRegular + " form"}>{data.form3}</p>
            <hr className="line"/>
          </div>
          <div>
            <p className={font.nexaRegular + " form"}>{data.form}</p>
            <p className={font.nexaRegular + " form"}>{data.form4}</p>
            <hr className="line"/>
          </div>
        </div>
      </div>
    </StyledForm>
  )
}


export default Form

const StyledForm = styled.div`
  position: relative;
  margin-top: 15vh;
  .title{
    text-align: center;
    margin-bottom: 2em;
    color: #717171;
    text-transform: uppercase;
    letter-spacing: 0.3em;
  }
  .btn{
    margin-top: 2em;
    display: flex;
    align-items: center;
    width: fit-content;
    position: relative;
    p{padding-right: 4em;}
    img{
      height: 1.5em;
      margin-right: 0.5em;
      position: absolute;
      right: 0;
    }
  }
  .box{
    color: #fff;
    position: relative;
    background-color: #02374e;
    padding: 8vw;
  }
  .forms{
    margin-top: 7vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: calc(1rem + 32 * (100vw - 1000px) / 920);
    grid-column-gap: calc(1rem + 32 * (100vw - 1000px) / 920);
    text-align: center;
    .form{letter-spacing: 0.3em;}
  }
  h1{font-size: calc(50px + 60 * (100vw - 769px) / 1791); color: #e6e6e6; text-align: center; margin: 0 0 1em;}
  p{font-size: calc(12px + 4 * (100vw - 1000px) / 920); line-height: 1.7em;}
  .line{
    width: 40%;
    border: none;
    margin: 1em auto;
    border-top: #fff 1px solid;
  }
`