import React from "react"
import SliderReact from "react-slick"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import Card from "./card"

const Especialidades = ({data}) => {

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    swipeToSlide: true,
    arrows: false,
    centerMode: true,
    variableWidth: true,
    slidesToScroll: 3
  };


  return(
    <StyledContainer>
      <h4 className={font.nexaBold}>{data.subtitle}</h4>
      <h1 className={font.financierMediumItalic}>{data.title}</h1>
      <div className="list">
        <SliderReact {...settings}>
          {data.specialties.map((data, i)=>(
            <Card data={data} key={i} />
          ))}
        </SliderReact>
      </div>
    </StyledContainer>
  )
}


export default Especialidades

const StyledContainer = styled.div`
  margin: 10vh 0;
  h4, h1{text-align: center;}
  h1{color: #e6e6e6; margin: 0.8em auto; width: 80%; line-height: 0.8em;}
  h4{color: #717171; text-transform: uppercase;}
  .list{
    width: 100%;
    margin: 3vh auto;
    overflow: hidden;
    padding: 15px 0;
  }


  .slick-track{
    height: 100%;
    display: flex;
  }
  .slick-slide{
    div{margin: 0 5px;}
  }
`